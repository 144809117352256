import React from "react";
import Banner from "../components/banner/banner";
import PartnerSection from "../components/PartnerSection/partner";
import ROI from "../components/ROISection/ROI";
import FooterSection from "../components/FooterSection/footer";
import SecondBanner from "../components/SecondBannerSection/SecondBanner";
import SolutionSection from "../components/SolutionSection/SolutionSection";
import BenefitsSection from "../components/BenefitsSection/BenefitsSection";
import Features from "../components/Features/Features";
import About from "../components/AboutSection/About";
import FAQ from "../components/FAQSection/FAQ";
import "../components/global/primary.css"
import "../components/global/grid.css";
export default function AgentLabel() {
  return (
    <>
      <Banner />
      <PartnerSection />
      <About />
      <SolutionSection />
      <BenefitsSection />
      <Features />
      <ROI />
      <FAQ />
      <SecondBanner />
      <FooterSection />
    </>
  );
}
