import React from "react";
import "./Forms.css";
import YourInformation from "./YourInformation";
export default function Forms({ step, setStep, setShowVideo }) {
  return (
    <div
      className="form-wrapper"
      style={{ paddingBottom: step === 1 ? "3rem" : "0" }}
    >
      {step === 1 && <YourInformation setStep={setStep} setShowVideo={setShowVideo} />}
    </div>
  );
}
