import React from "react";
import "./About.css";
import multiFormatSupport from "../../assets/AboutSection/multi-format-support.jpg";
import collaborativeWorkflow from "../../assets/AboutSection/collaborative-workflow.jpg";
import preAnnotationAutomation from "../../assets/AboutSection/pre-annotation-automation.jpg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import StyledBorder from "../StyledBorder/StyledBorder";
export default function About() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`about-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="one"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <StyledBorder
              style={{
                display: "inline-block",
                marginBottom: "2rem",
              }}
              width={"8px"}
              color={"#E9FEAB"}
              boxColor={"#225D53"}
            >
              <div className="heading-tag">
                <span>About</span>
              </div>
            </StyledBorder>
            <div className="heading-description">
              <h2>AI-driven Data Annotation with Agent Label</h2>
              <p>
                Label data quickly with a suite of AI-assisted annotation tools
                to support machine learning and AI applications
              </p>
            </div>
          </div>
          <div className="about-cards-wrapper row">
            <div className="col-12 col-sm-4">
              <StyledBorder
                width={"15px"}
                color={"#C0F816"}
                boxColor={"#225D53"}
                style={{ height: "100%" }}
              >
                <div className="about-card-inner">
                  <div className="card-image">
                    <img src={multiFormatSupport} alt="about-card-image" />
                  </div>
                  <div className="card-text">
                    <h3>Multi-Format Support</h3>
                    <p>
                      Versatile annotation tools for bounding boxes, polygons
                      and key points
                    </p>
                  </div>
                </div>
              </StyledBorder>
            </div>
            <div className="col-12 col-sm-4">
              <StyledBorder
                width={"15px"}
                color={"#C0F816"}
                boxColor={"#225D53"}
                style={{ height: "100%" }}
              >
                <div className="about-card-inner">
                  <div className="card-image">
                    <img src={collaborativeWorkflow} alt="about-card-image" />
                  </div>
                  <div className="card-text">
                    <h3>Pre-Annotation and Automation</h3>
                    <p>
                      Automatically generate initial annotations for images to
                      accelerate the annotating process
                    </p>
                  </div>
                </div>
              </StyledBorder>
            </div>
            <div className="col-12 col-sm-4">
              <StyledBorder
                width={"15px"}
                color={"#C0F816"}
                boxColor={"#225D53"}
                style={{ height: "100%" }}
              >
                <div className="about-card-inner">
                  <div className="card-image">
                    <img src={preAnnotationAutomation} alt="about-card-image" />
                  </div>
                  <div className="card-text">
                    <h3>Collaborative Workflow</h3>
                    <p>
                      Enable team-wide annotation with real-time collaboration
                    </p>
                  </div>
                </div>
              </StyledBorder>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
