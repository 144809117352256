import React, { useEffect, useState } from "react";
import "./Solution.css";
import advancedImageAnnotation from "../../assets/SolutionSection/advanced-image-annotation.jpg";
import CuboidAnnotation from "../../assets/SolutionSection/3d-cuboid-annotation.jpg";
import advancedPointCloudAnnotation from "../../assets/SolutionSection/advanced-point-cloud-annotation.jpg";
import skeletonAnnotation from "../../assets/SolutionSection/skeleton-annotation.jpg";
import didcomImageAnnotation from "../../assets/SolutionSection/DICOM-image-annotation.jpg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import StyledBorder from "../StyledBorder/StyledBorder";
export default function SolutionSection() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [activeTab, setActiveTab] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getWidthStyle = () => {
    if (width < 769) {
      return { width: "100%" }; // Full width for small screens
    } else if (width < 1200) {
      return { width: "45%" }; // Half width for medium screens
    } else return { width: "35%" };
  };
  const handleTabSwitch = (index) => {
    setActiveTab(index);
  };

  return (
    <section
      className={`solutions-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="two"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <StyledBorder
              style={{
                display: "inline-block",
                marginBottom: "2rem",
              }}
              width={"8px"}
              color={"#FFFFFF"}
              boxColor={"#D7A200"}
            >
              <div className="heading-tag">
                <span>Solutions</span>
              </div>
            </StyledBorder>
            <div className="heading-description">
              <h2>Label every data type</h2>
              <p>
                Label data quickly with a suite of AI-assisted annotation tools
                to augment human labeling or fully automate your data labeling
                pipeline.
              </p>
            </div>
          </div>
          <div className="first-tab-switch-wrapper">
            <div className="tab-links-container">
              <div
                className={`${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabSwitch(1)}
              >
                <p>Images</p>
              </div>
              <div
                className={`${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabSwitch(2)}
              >
                <p>3D Cuboid</p>
              </div>
              <div
                className={`${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabSwitch(3)}
              >
                <p>Point Clouds</p>
              </div>
              <div
                className={`${activeTab === 4 ? "active" : ""}`}
                onClick={() => handleTabSwitch(4)}
              >
                <p>Skeleton</p>
              </div>
              <div
                className={`${activeTab === 5 ? "active" : ""}`}
                onClick={() => handleTabSwitch(5)}
              >
                <p>DICOM</p>
              </div>
              {/* <div
                className={`${activeTab === 6 ? "active" : ""}`}
                onClick={() => handleTabSwitch(6)}
              >
                <p>DICOM</p>
              </div> */}
            </div>
            <div className="first-tab-switch-body">
              {activeTab === 1 && (
                <div
                  className={`first-tab-switch-content ${
                    activeTab === 1 ? "active" : ""
                  }`}
                >
                  <div className="tab-switch-inner">
                    <div className="tab-switch-text">
                      <h3>Advanced Image Annotation</h3>
                      <ul>
                        <li>
                          <h4>Instance Segmentation</h4>
                          <p>
                            Precisely label each object instance in an image for
                            detailed analysis and training.
                          </p>
                        </li>
                        <li>
                          <h4>Semantic Segmentation</h4>
                          <p>
                            Annotate every pixel in an image to categorize
                            regions for comprehensive understanding
                          </p>
                        </li>
                        <li>
                          <h4>Object Detection</h4>
                          <p>
                            Identify and classify multiple objects within an
                            image to enhance AI model accuracy
                          </p>
                        </li>
                      </ul>
                    </div>
                    <StyledBorder
                      width={"15px"}
                      color={"#FFE595"}
                      boxColor={"#FFC000"}
                      style={{
                        ...getWidthStyle(),
                      }}
                    >
                      <div className="tab-switch-image">
                        <img
                          src={advancedImageAnnotation}
                          alt="solutions-image"
                        />
                      </div>
                    </StyledBorder>
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div
                  className={`first-tab-switch-content ${
                    activeTab === 2 ? "active" : ""
                  }`}
                >
                  <div className="tab-switch-inner">
                    <div className="tab-switch-text">
                      <h3>3D Cuboid Annotation</h3>
                      <ul>
                        <li>
                          <h4>Bounding Box Creation</h4>
                          <p>
                            Generate 3D bounding boxes for objects in spatial
                            data, ideal for autonomous driving applications.
                          </p>
                        </li>
                        <li>
                          <h4>Depth Perception</h4>
                          <p>
                            Annotate objects in 3D space to provide depth
                            information, crucial for robotics and AR/VR.
                          </p>
                        </li>
                        <li>
                          <h4>Object Tracking</h4>
                          <p>
                            Track object movement across frames, enhancing
                            accuracy in motion analysis and prediction
                          </p>
                        </li>
                      </ul>
                    </div>
                    <StyledBorder
                      width={"15px"}
                      color={"#FFE595"}
                      boxColor={"#FFC000"}
                      style={{
                        ...getWidthStyle(),
                      }}
                    >
                      <div className="tab-switch-image">
                        <img src={CuboidAnnotation} alt="solutions-image" />
                      </div>
                    </StyledBorder>
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div
                  className={`first-tab-switch-content ${
                    activeTab === 3 ? "active" : ""
                  }`}
                >
                  <div className="tab-switch-inner">
                    <div className="tab-switch-text">
                      <h3>Advanced Point Cloud Annotation</h3>
                      <ul>
                        <li>
                          <h4>Point Cloud Segmentation</h4>
                          <p>
                            Classify and segment various regions in a point
                            cloud to identify distinct object classes for
                            enhanced analysis.
                          </p>
                        </li>
                        <li>
                          <h4>3D Object Detection</h4>
                          <p>
                            Detect and classify objects within a point cloud to
                            improve the accuracy of 3D models and AI
                            applications.
                          </p>
                        </li>
                        <li>
                          <h4>Surface Normal Estimation</h4>
                          <p>
                            Estimate the normal vectors of surfaces in the point
                            cloud, providing crucial geometric information for
                            various applications.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <StyledBorder
                      width={"15px"}
                      color={"#FFE595"}
                      boxColor={"#FFC000"}
                      style={{
                        ...getWidthStyle(),
                      }}
                    >
                      <div className="tab-switch-image">
                        <img
                          src={advancedPointCloudAnnotation}
                          alt="solutions-image"
                        />
                      </div>
                    </StyledBorder>
                  </div>
                </div>
              )}
              {activeTab === 4 && (
                <div
                  className={`first-tab-switch-content ${
                    activeTab === 4 ? "active" : ""
                  }`}
                >
                  <div className="tab-switch-inner">
                    <div className="tab-switch-text">
                      <h3>Skeleton Annotation</h3>
                      <ul>
                        <li>
                          <h4>Pose Estimation</h4>
                          <p>
                            Label key points on the human body to facilitate
                            accurate pose estimation for sports analytics.
                          </p>
                        </li>
                        <li>
                          <h4>Activity Recognition</h4>
                          <p>
                            Annotate skeletal structures to identify and analyze
                            human activities in video data
                          </p>
                        </li>
                        <li>
                          <h4>Motion Capture</h4>
                          <p>
                            Precisely track body movements for applications in
                            animation, healthcare, and ergonomics.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <StyledBorder
                      width={"15px"}
                      color={"#FFE595"}
                      boxColor={"#FFC000"}
                      style={{
                        ...getWidthStyle(),
                      }}
                    >
                      <div className="tab-switch-image">
                        <img src={skeletonAnnotation} alt="solutions-image" />
                      </div>
                    </StyledBorder>
                  </div>
                </div>
              )}
              {activeTab === 5 && (
                <div
                  className={`first-tab-switch-content ${
                    activeTab === 5 ? "active" : ""
                  }`}
                >
                  <div className="tab-switch-inner">
                    <div className="tab-switch-text">
                      <h3>DICOM Image Annotation</h3>
                      <ul>
                        <li>
                          <h4>Medical Image Segmentation</h4>
                          <p>
                            Annotate regions of interest in DICOM images for
                            precise medical diagnosis and research.
                          </p>
                        </li>
                        <li>
                          <h4>Tumor Detection</h4>
                          <p>
                            Identify and label tumors in medical scans, aiding
                            in early detection and treatment planning.
                          </p>
                        </li>
                        <li>
                          <h4>Anatomical Structure Labeling</h4>
                          <p>
                            Provide detailed annotations of anatomical
                            structures, supporting advanced medical imaging
                            studies.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <StyledBorder
                      width={"15px"}
                      color={"#FFE595"}
                      boxColor={"#FFC000"}
                      style={{
                        ...getWidthStyle(),
                      }}
                    >
                      <div className="tab-switch-image">
                        <img
                          src={didcomImageAnnotation}
                          alt="solutions-image"
                        />
                      </div>
                    </StyledBorder>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
