import React from "react";
import "./roi.css";
import roiImage from "../../assets/ROI/roi-image.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import NumberDisplay from "../NumberDisplay";
import StyledBorder from "../StyledBorder/StyledBorder";
export default function ROI() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`roi-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <StyledBorder
              style={{
                display: "inline-block",
                marginBottom: "2rem",
              }}
              width={"8px"}
              color={"#FFFFFF"}
              boxColor={"#740037"}
            >
              <div className="heading-tag">
                <span>ROI</span>
              </div>
            </StyledBorder>
            <div className="heading-description">
              <h2>Unlock the ROI Potential with Agent Label</h2>
              <p>
                Label data efficiently with AI-assisted annotation tools to
                streamline your machine learning workflows.
              </p>
            </div>
          </div>
          <div className="roi-content-wrapper">
            <div className="roi-stats-cards row">
              <div className="roi-card">
                <NumberDisplay
                  first={20}
                  last={7}
                  char="+"
                  isVisible={isVisible}
                />
                <h4>Annotation Formats</h4>
                <p>
                  Supporting diverse needs from object detection to image
                  segmentation.
                </p>
              </div>
              <div className="roi-card">
                <NumberDisplay
                  first={30}
                  last={10}
                  char="x"
                  isVisible={isVisible}
                />
                <h4>Productivity Boost</h4>
                <p>
                  Streamlined workflows and user-friendly interface accelerate
                  annotation speed.
                </p>
              </div>

              <div className="roi-card">
                <NumberDisplay
                  first={19}
                  last={60}
                  char="%"
                  isVisible={isVisible}
                />
                <h4>Cost Reduction</h4>
                <p>Decreases manual effort and labor costs via automation.</p>
              </div>
              <div className="roi-card">
                <NumberDisplay
                  first={15}
                  last={25}
                  char="x"
                  isVisible={isVisible}
                />
                <h4>Faster Annotation Time</h4>
                <p>
                  Agent Label speeds up the annotation process by 25x through
                  AI-powered automation, allowing teams to handle larger
                  datasets in less time.{" "}
                </p>
              </div>
            </div>
            <div className="roi-image">
              <img src={roiImage} alt="roi-image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
