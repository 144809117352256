import "./FormComponents.css";

export const FormButtons = ({ children }) => {
  return <div className="buttons-wrapper">{children}</div>;
};

export const CancelButton = ({ name, onClick }) => {
  return (
    <button className="cancel-button" onClick={onClick}>
      <span style={{margin:"auto"}}>{name}</span>
    </button>
  );
};

export const SubmitButton = ({ name, boolVar = true, onClick }) => {
  return (
    <button
      className="submit-button"
      onClick={onClick}
      style={{
        cursor: boolVar ? "pointer" : "not-allowed",
        background: boolVar ? "#740037" : "#7400378F",
        transform: boolVar ? "" : "none",
        boxShadow: boolVar ? "" : "none",
      }}
    >
      <span style={{margin:"auto"}}>{name}</span>
    </button>
  );
};
