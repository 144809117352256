import React, { useState } from "react";
import BookDemoSidebar from "../../components/Book Demo Sidebar/BookDemoSidebar";
import Forms from "../../components/Forms/Forms";
import "../../components/global/primary-watch-demo.css";
import "../../components/global/grid-watch-demo.css";
import "./WatchDemo.css";
import VideoComponent from "../../components/VideoComponent/VideoComponent";

export default function BookDemo() {
  const [step, setStep] = useState(1);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div>
      {!showVideo && (
        <>
          <div
            className="body-wrapper"
            style={{ height: step <= 2 ? "96vh" : "100%" }}
          >
            <BookDemoSidebar step={step} />
            <Forms step={step} setStep={setStep} setShowVideo={setShowVideo} />
          </div>
        </>
      )}
      {showVideo && <VideoComponent />}
    </div>
  );
}
