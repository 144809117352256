import React, { useState } from "react";
import "./FAQ.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import StyledBorder from "../StyledBorder/StyledBorder";
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqData = [
    {
      question: "What is Agent Label?",
      answer:
        "Agent Label is an advanced AI-driven agent designed to streamline and automate the process of data annotation for various machine learning and AI applications, supporting multiple annotation formats like bounding boxes, polygons, masks, and keypoints.",
    },
    {
      question: "How will Agent Label integrate into my workflow?",
      answer:
        "Agent Label supports integration with various data management systems and can export annotations in popular formats like COCO, YOLO, and PASCAL VOC.",
    },
    {
      question: "What types of annotations does Agent Label support?",
      answer:
        "Agent Label supports multiple annotation formats, including bounding boxes for object detection, polygons for segmentation, masks for pixel-wise annotations, and keypoints for pose estimation.",
    },
    {
      question:
        "Is Agent Label secure and compliant with data protection standards?",
      answer:
        "Yes, Agent Label adheres to data security standards like HIPAA and GDPR, offering robust security measures to protect data integrity and privacy.",
    },
    {
      question: "How can I book a demo and get started with Agent Label?",
      answer:
        "To book a demo and get started with Agent Label, you can visit our website and fill out the demo request form. Our team will reach out to schedule a personalized demo and guide you through the setup process.",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`first-accordion ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="five"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <StyledBorder
              style={{
                display: "inline-block",
                marginBottom: "2rem",
              }}
              width={"8px"}
              color={"#FFFFFF"}
              boxColor={"#6A6868"}
            >
              <div className="heading-tag">
                <span>FAQ</span>
              </div>
            </StyledBorder>
            <h2>Label every data type</h2>
          </div>
          <div className="accordion">
            {faqData.map((item, index) => (
              <div className="accordion-item" key={index}>
                <button
                  id={`accordion-button-${index + 1}`}
                  aria-expanded={activeIndex === index ? "true" : "false"}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="accordion-title">{item.question}</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
