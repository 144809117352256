import React, { useState } from "react";
import "./VideoComponent.css";
import agentLabelLogo from "../../assets/agent-label-logo.svg";
import thumbnailImage from "../../assets/agentlavel-thumnail.png";
import { Link,useNavigate } from 'react-router-dom';

const VideoComponent = () => {
    const [videoPlaying, setVideoPlaying] = useState(false);
    const playVideo = () => {
        setVideoPlaying(true);
    };
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate("/");
    };

    return (
        <div>
            <div className="video-header-wrapper">
                <div className="video-header">
                    <Link to="/">
                        <img src={agentLabelLogo} alt="agent-label" />
                    </Link>
                    <button onClick={handleButtonClick}>Back to Home</button>
                </div>
            </div>
            <div className="video-sidebar-wrapper">
                <div className="top-video-title">
                    <h1>Dataset labeling like never before</h1>
                    <p>Eliminate endless comment threads and confusion.</p>
                    <p>Use Clips to record a video and share your ideas clearly.</p>
                </div>
                <div className="video-section">
                    <div className="video-section-wrapper">
                        <div
                            className="video-section-inner"
                            id="video-section-inner"
                            style={{
                                backgroundImage: videoPlaying ? "none" : `url(${thumbnailImage})`
                            }}
                        >
                            {videoPlaying ? (
                                <iframe
                                    src="https://www.youtube.com/embed/x6A3uU-up8M?autoplay=1&si=Bedf7LcLfpm0d3uH"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Product Demo Video"
                                />
                            ) : (
                                <div className="video-section-button" onClick={playVideo}>
                                    <img
                                        src="https://9471087.fs1.hubspotusercontent-na1.net/hubfs/9471087/resources/xenonstack-usecase-playbutton.svg"
                                        alt="Video Icon"
                                    />
                                    <button className="btn"></button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoComponent;
