export const base_url = "https://akira-ai-auth.lab.neuralcompany.team";
export const watch_demo_api_link =
  "https://api.hsforms.com/submissions/v3/integration/submit/8162471/823e901c-0d25-4939-bb22-bb97545647a3";
export const api_link =
  "https://api.hsforms.com/submissions/v3/integration/submit/8162471/ef754929-1d69-481f-91f5-c230be221886";
const local = {
  info: {
    REACT_APP_WEBSITE_URL: "https://www.akira.ai",
  },
  bookdemo: {
    Link: "https://demo.akira.ai/agentlabel/",
  },
};

const dev = {
  auth: {
    REACT_APP_AUTH_URL: "https://akiraai-auth.neuralcompany.team/api/auth",
  },
  app: {
    WORKSPACE_DOMAIN_NAME: ".neuralcompany.team",
  },
  info: {
    REACT_APP_WEBSITE_URL: "https://akiraai.neuralcompany.team",
  },
  bookdemo: {
    Link: "https://agentlabel-bookdemo.lab.neuralcompany.team/agentlabel",
  },
};

const prod = {
  auth: {
    REACT_APP_AUTH_URL: "https://app.akira.ai/api/auth",
  },
  app: {
    WORKSPACE_DOMAIN_NAME: ".akira.ai",
  },
  info: {
    REACT_APP_WEBSITE_URL: "https://www.akira.ai",
  },
  bookdemo: {
    Link: "https://demo.akira.ai/agentlabel/",
  },
};

const config =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE === "development"
    ? dev
    : // : process.env.REACT_APP_STAGE === "uat"
      // ? uat
      local;
// eslint-disable-next-line
export default {
  ...config,
};
