import React from "react";
import styled from "styled-components";
// ${(props) => props.width}
const StyledDiv = styled.div`
  position: relative;
  width: 25%;
  @media (max-width: 1440px) {
    width: 30%;
  }

  @media (max-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const CornerBox = styled.div`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  background-color: ${(props) => props.color};
  border: 1px solid ${(props) => props.boxcolor};
`;
const TopLeft = styled(CornerBox)`
  top: calc(${(props) => props.width} / -2);
  left: calc(${(props) => props.width} / -2);
`;
const TopRight = styled(CornerBox)`
  top: calc(${(props) => props.width} / -2);
  right: calc(${(props) => props.width} / -2);
`;
const BottomRight = styled(CornerBox)`
  bottom: calc(${(props) => props.width} / -2);
  right: calc(${(props) => props.width} / -2);
`;
const BottomLeft = styled(CornerBox)`
  bottom: calc(${(props) => props.width} / -2);
  left: calc(${(props) => props.width} / -2);
`;

export default function StyledBorderWatchDemo({
  width,
  style,
  color,
  boxcolor,
  children,
}) {
  return (
    <StyledDiv width={width} style={{ ...style }}>
      {children}
      <TopLeft width={width} color={color} boxcolor={boxcolor} />
      <TopRight width={width} color={color} boxcolor={boxcolor} />
      <BottomLeft width={width} color={color} boxcolor={boxcolor} />
      <BottomRight width={width} color={color} boxcolor={boxcolor} />
    </StyledDiv>
  );
}
