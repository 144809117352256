import React from "react";
import "./App.css";
import "./components/global/primary.css";
import "./components/global/grid.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorComponent from "./components/Error Component/ErrorComponent";
import AgentLabel from "./pages/AgentLabel";
import BookDemo from "./pages/WatchDemo/WatchDemo";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AgentLabel />} />
          <Route path="/watchdemo" element={<BookDemo />} />
          <Route path="*" element={<ErrorComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
