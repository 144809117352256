import { useEffect, useState } from "react";

const ImagePreloader = (src) => {
  const [loadedImage, setLoadedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoadedImage(src);
      setIsLoading(false);
    };
    img.onerror = () => setIsLoading(false); // Handle error

    return () => {
      setLoadedImage(null);
      setIsLoading(true); // Reset state on unmount
    };
  }, [src]);

  return { loadedImage, isLoading };
};

export default ImagePreloader;
