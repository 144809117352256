import React from "react";
import "./secondbanner.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import config from "../../config";
import StyledBorder from "../StyledBorder/StyledBorder";
export default function SecondBanner() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`second-banner-section ${
        isVisible ? "section-animation" : ""
      }`}
      ref={ref}
    >
      <div className="container">
        <StyledBorder
          style={{
            display: "inline-block",
            marginBottom: "2rem",
          }}
          width={"20px"}
          color={"#E5FFF4"}
          boxColor={"#195865"}
        >
          <div className="second-banner-inner">
            <div className="second-banner-heading">
              <h2>
                Agentic AI-Driven platform that streamlines annotation and
                labelling
              </h2>
              <p>
                Unlock the potential of Agent Label, an AI-driven platform
                designed for you to achieve rapid and accurate annotation of
                images, all tailored to enhance your workflow and efficiency.
              </p>
            </div>
            <div
              style={{ display: "flex", gap: "20px" }}
              className="second-banner-button-wrapper"
            >
              <a href={config.bookdemo.Link}>
                <StyledBorder
                  width={"10px"}
                  color={"#62D8A5"}
                  boxColor={"#000000"}
                  style={{ margin: "0 auto" }}
                >
                  <button className="second-banner-button">
                    <p>Book Demo</p>
                  </button>
                </StyledBorder>
              </a>
              <a href="/watchdemo">
                <StyledBorder
                  width={"10px"}
                  color={"#62D8A5"}
                  boxColor={"#000000"}
                  style={{ margin: "0 auto" }}
                >
                  <button
                    style={{ backgroundColor: "white" }}
                    className="second-banner-button"
                  >
                    <p style={{ color: "#35a675" }}>Watch Demo</p>
                  </button>
                </StyledBorder>
              </a>
            </div>
          </div>
        </StyledBorder>
      </div>
    </section>
  );
}
