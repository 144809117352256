import React, { useEffect, useState } from "react";

export default function NumberDisplay({ first, last, char, isVisible }) {
  const [currentNumber, setCurrentNumber] = useState(first);
  useEffect(() => {
    // Reset to initial number when it becomes visible
    if (isVisible) {
      setCurrentNumber(first);
    }
  }, [isVisible, first]);
  useEffect(() => {
    if (currentNumber === last) return;
    const interval = setInterval(() => {
      setCurrentNumber((prev) => {
        if (prev < last) {
          return prev + 1;
        } else if (prev > last) {
          return prev - 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 2000 / Math.abs(last - first));

    return () => clearInterval(interval);
  }, [currentNumber, first, last]);

  return (
    <h3>
      {currentNumber}
      {char}
    </h3>
  );
}
