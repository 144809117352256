import React, { useState } from "react";
import "./Features.css";
import algorithmAssistance from "../../assets/FeatureSection/algorithmic-assistance.png";
import crossPlatformIntegration from "../../assets/FeatureSection/cross-platform-integration.png";
import intelligentAssistance from "../../assets/FeatureSection/intelligent-assistance.png";
import objectSegmentation from "../../assets/FeatureSection/object-segmentation.png";
import qualityControlTools from "../../assets/FeatureSection/quality-control-tools.png";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import StyledBorder from "../StyledBorder/StyledBorder";

export default function Features() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSwitch = (index) => {
    setActiveTab(index);
    document.documentElement.style.setProperty("--tab-index", index);
  };
  return (
    <section
      className={`features-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="four"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <StyledBorder
              style={{
                display: "inline-block",
                marginBottom: "2rem",
              }}
              width={"8px"}
              color={"#FFFFFF"}
              boxColor={"#6A6868"}
            >
              <div className="heading-tag">
                <span>Features</span>
              </div>
            </StyledBorder>
            <div className="heading-description">
              <h2>Advanced features for modern workflows</h2>
              <p>
                Enhance productivity and accuracy with cutting-edge tools
                designed for efficient data annotation.
              </p>
            </div>
          </div>
          <div className="second-tab-switch-wrapper">
            <div className="tabs-container">
              <div
                className={`${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabSwitch(1)}
              >
                <h3>Object Segmentation</h3>
                <div className="tab-switch-paragraph">
                  <p>
                    Enable pixel-accurate object labeling with just a few
                    clicks. Ideal for precise labeling.
                  </p>
                </div>
              </div>
              <div
                className={`${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabSwitch(2)}
              >
                <h3>Quality Control Tools</h3>
                <div className="tab-switch-paragraph">
                  <p>
                  Built-in quality control tools  to ensure that annotations meet the highest quality standards before being used in training model. 
                  </p>
                </div>
              </div>
              <div
                className={`${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabSwitch(3)}
              >
                <h3>Algorithmic assistance</h3>
                <div className="tab-switch-paragraph">
                  <p>
                  Annotate with greater efficiency using advanced automated interactive algorithms such as histogram equalization, and more.
                  </p>
                </div>
              </div>
              <div
                className={`${activeTab === 4 ? "active" : ""}`}
                onClick={() => handleTabSwitch(4)}
              >
                <h3>Cross-Platform Integration</h3>
                <div className="tab-switch-paragraph">
                  <p>
                  Compatibility with various data formats and integration capabilities with existing systems. 
                  </p>
                </div>
              </div>
              <div
                className={`${activeTab === 5 ? "active" : ""}`}
                onClick={() => handleTabSwitch(5)}
              >
                <h3>Intelligent Assistance</h3>
                <div className="tab-switch-paragraph">
                  <p>
                  AI-driven suggestions and pre-annotations to speed up the labeling process and improve accuracy.
                  </p>
                </div>
              </div>
            </div>
            <div className="second-tab-switch-body">
              {activeTab === 1 && (
                <div
                  className={`second-tab-switch-content ${
                    activeTab === 1 ? "active" : ""
                  }`}
                >
                  <StyledBorder
                    width={"20px"}
                    color={"#DFE1EF"}
                    boxColor={"#8316F9"}
                  >
                    <div className="second-tab-switch-image">
                      <img src={algorithmAssistance} alt="feature-image" />
                    </div>
                  </StyledBorder>
                </div>
              )}
              {activeTab === 2 && (
                <div
                  className={`second-tab-switch-content ${
                    activeTab === 2 ? "active" : ""
                  }`}
                >
                  <StyledBorder
                    width={"20px"}
                    color={"#DFE1EF"}
                    boxColor={"#8316F9"}
                  >
                    <div className="second-tab-switch-image">
                      <img src={crossPlatformIntegration} alt="feature-image" />
                    </div>
                  </StyledBorder>
                </div>
              )}
              {activeTab === 3 && (
                <div
                  className={`second-tab-switch-content ${
                    activeTab === 3 ? "active" : ""
                  }`}
                >
                  <StyledBorder
                    width={"20px"}
                    color={"#DFE1EF"}
                    boxColor={"#8316F9"}
                  >
                    <div className="second-tab-switch-image">
                      <img src={intelligentAssistance} alt="feature-image" />
                    </div>
                  </StyledBorder>
                </div>
              )}
              {activeTab === 4 && (
                <div
                  className={`second-tab-switch-content ${
                    activeTab === 4 ? "active" : ""
                  }`}
                >
                  <StyledBorder
                    width={"20px"}
                    color={"#DFE1EF"}
                    boxColor={"#8316F9"}
                  >
                    <div className="second-tab-switch-image">
                      <img src={objectSegmentation} alt="feature-image" />
                    </div>
                  </StyledBorder>
                </div>
              )}
              {activeTab === 5 && (
                <div
                  className={`second-tab-switch-content ${
                    activeTab === 5 ? "active" : ""
                  }`}
                >
                  <StyledBorder
                    width={"20px"}
                    color={"#DFE1EF"}
                    boxColor={"#8316F9"}
                  >
                    <div className="second-tab-switch-image">
                      <img src={qualityControlTools} alt="feature-image" />
                    </div>
                  </StyledBorder>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
