import React from "react";
import "./Sidebar.css";
import agentLabelLogo from "../../assets/agent-label-logo.svg";
import StyledBorderWatchDemo from "../StyledBorder/StyledBorderWatchDemo";
import { Link } from 'react-router-dom';
export default function BookDemoSidebar({ step }) {
  return (
    <StyledBorderWatchDemo width={"15px"} color={"#E7549A"} boxColor={"#740037"}>
      <div className="sidebar-wrapper">
        <div className="sidebar-content">
          <Link to="/">
            <img src={agentLabelLogo} alt="agent-label" />
          </Link>
          <h1>Watch Demo</h1>
          <p>Watch the demo video for a more detailed and informative overview of our product.</p>
        </div>
      </div>
    </StyledBorderWatchDemo>
  );
}
