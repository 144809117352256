import React from "react";
import "./BenefitsSection.css";
import benefitCard1 from "../../assets/BenefitsSection/benefit-card-1.svg";
import benefitCard2 from "../../assets/BenefitsSection/benefit-card-2.svg";
import benefitCard3 from "../../assets/BenefitsSection/benefit-card-3.svg";
import benefitCard4 from "../../assets/BenefitsSection/benefit-card-4.svg";
import benefitCard5 from "../../assets/BenefitsSection/benefit-card-5.svg";
import benefitCard6 from "../../assets/BenefitsSection/benefit-card-6.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import StyledBorder from "../StyledBorder/StyledBorder";

const BenefitsSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`benefits-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="three"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <StyledBorder
              style={{
                display: "inline-block",
                marginBottom: "2rem",
              }}
              width={"8px"}
              color={"#FFFFFF"}
              boxColor={"#4A03A5"}
            >
              <div className="heading-tag">
                <span>Benefits</span>
              </div>
            </StyledBorder>
            <div className="heading-description">
              <h2>AI-Powered Dataset Management</h2>
              <p>
                Label data quickly with a suite of AI-assisted annotation tools
                to augment human labeling or fully automate your data labeling
                pipeline.
              </p>
            </div>
          </div>
          <div className="benefits-cards-wrapper row">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="benefits-card-inner">
                <div className="benefits-card-icon">
                  <img src={benefitCard1} alt="benefit-card-icon" />
                </div>
                <div className="benefits-card-text">
                  <h3>Efficiency in Object Detection</h3>
                  <p>
                  Automates annotations, speeding up processes for quicker project completion.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="benefits-card-inner">
                <div className="benefits-card-icon">
                  <img src={benefitCard2} alt="benefit-card-icon" />
                </div>
                <div className="benefits-card-text">
                  <h3>Precision in Medical Imaging</h3>
                  <p>
                  Ensures accurate annotations critical for reliable medical diagnostics and treatment
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="benefits-card-inner">
                <div className="benefits-card-icon">
                  <img src={benefitCard3} alt="benefit-card-icon" />
                </div>
                <div className="benefits-card-text">
                  <h3>Support for Multiple Annotation Formats</h3>
                  <p>
                  Accommodates various needs, from bounding boxes to segmentation masks
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="benefits-card-inner">
                <div className="benefits-card-icon">
                  <img src={benefitCard4} alt="benefit-card-icon" />
                </div>
                <div className="benefits-card-text">
                  <h3>Scalability for Projects</h3>
                  <p>
                  Manages extensive datasets simultaneously, improving retail analytics and surveillance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="benefits-card-inner">
                <div className="benefits-card-icon">
                  <img src={benefitCard5} alt="benefit-card-icon" />
                </div>
                <div className="benefits-card-text">
                  <h3>Collaboration and Quality Assurance in Team</h3>
                  <p>
                  Enables real-time collaboration, ensuring consistency and high annotation quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="benefits-card-inner">
                <div className="benefits-card-icon">
                  <img src={benefitCard6} alt="benefit-card-icon" />
                </div>
                <div className="benefits-card-text">
                  <h3>Security and Compliance for Sensitive Data</h3>
                  <p>
                  Protects sensitive data, adhering to HIPAA and GDPR standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
