import React, { useEffect, useState } from "react";
import FormHeading from "../FormComponents/FormHeading";
import "./Forms.css";
import {
  CancelButton,
  FormButtons,
  SubmitButton,
} from "../FormComponents/FormComponents";
import {
  countries,
  IndustryList,
  jobTitles,
} from "../FormComponents/OptionsList";
import styled from "styled-components";
import { watch_demo_api_link } from "../../config";
const StyledSpan = styled.span`
  color: red;
`;
export default function YourInformation({ setStep, setShowVideo }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    industry: "",
    jobTitle: "",
    team: "",
    country: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    companyName: "",
    industry: "",
    jobTitle: "",
    team: "",
    country: "",
  });
  useEffect(() => {
    const backPressed = JSON.parse(sessionStorage.getItem("backPressed")) || [];
    if (backPressed.length === 0) {
      sessionStorage.removeItem("qaList");
      sessionStorage.removeItem("ansIndexes");
      sessionStorage.removeItem("formData");
      sessionStorage.removeItem("inputData");
    } else {
      sessionStorage.setItem("backPressed", JSON.stringify(false));
    }

    const savedData = sessionStorage.getItem("formData");

    if (savedData) {
      setFormData(JSON.parse(savedData));
    }

    sessionStorage.removeItem("formData");
  }, []);

  const validateField = (name, value) => {
    const updatedErrors = { ...errors };
    switch (name) {
      case "name":
        if (!value.trim()) {
          updatedErrors.name = "Name is required.";
        } else if (/^\s/.test(value)) {
          updatedErrors.name = "Name cannot start with leading spaces.";
        } else if (value.length > 70) {
          updatedErrors.name = "Name must not exceed 70 characters.";
        } else if (!/^[A-Za-z\s.]+$/.test(value)) {
          updatedErrors.name = "Please enter correct name";
        } else {
          updatedErrors.name = "";
        }
        break;
      case "email":
        if (!value.trim()) {
          updatedErrors.email = "Email is required.";
        } else if (
          !/^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
        ) {
          updatedErrors.email = "Email address is invalid.";
        } else if (value.length > 254) {
          updatedErrors.email = "Email must not exceed 254 characters";
        } else {
          updatedErrors.email = "";
        }
        break;
      case "companyName":
        updatedErrors.companyName = value.trim()
          ? ""
          : value.length > 70
            ? "Company name must not exceed 70 characters"
            : /^\s/.test(value)
              ? "Please remove leading spaces"
              : "Company name is required.";
        break;
      case "industry":
        updatedErrors.industry = value ? "" : "Please select an industry.";
        break;
      case "jobTitle":
        updatedErrors.jobTitle = value ? "" : "Please select a job title.";
        break;
      case "team":
        updatedErrors.team = value.trim()
          ? ""
          : value.length > 70
            ? "Department/Team name must not exceed 70 characters"
            : /^\s/.test(value)
              ? "Please remove leading spaces"
              : "Department/Team is required.";
        break;
      case "country":
        updatedErrors.country = value ? "" : "Please select a country.";
        break;
      default:
        break;
    }
    return updatedErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const updatedErrors = validateField(name, value);
    setErrors(updatedErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const finalErrors = {};

    Object.keys(formData).forEach((key) => {
      finalErrors[key] = validateField(key, formData[key])[key];
    });

    if (formData.name.length < 2) {
      finalErrors.name = "Name must be at least 2 characters long.";
    }
    if (formData.companyName.trim()) {
      if (formData.companyName.length < 2) {
        finalErrors.companyName = "Enter at least 2 characters";
      } else if (!/[A-Za-z]/.test(formData.companyName)) {
        finalErrors.companyName = "Invalid Company name";
      }
    }
    if (formData.team.trim()) {
      if (formData.team.length < 2) {
        finalErrors.team = "Enter at least 2 characters";
      } else if (!/[A-Za-z]/.test(formData.team)) {
        finalErrors.team = "Invalid Department/Team name";
      }
    }
    if (Object.values(finalErrors).some((error) => error)) {
      setErrors(finalErrors);
      return;
    }
    Object.keys(formData).forEach((key) => {
      formData[key] = formData[key].trim();
    });
    sessionStorage.setItem("formData", JSON.stringify(formData));
    // setStep(2);
    const payload = {
      fields: [
        { name: "firstname", value: formData.name },
        { name: "email", value: formData.email },
        { name: "company", value: formData.companyName },
        { name: "jobtitle", value: formData.jobTitle },
        { name: "department", value: formData.team },
        { name: "industry_belongs_to", value: formData.industry },
        { name: "country_dropdown", value: formData.country },
      ],
    };

    try {
      const response = await fetch(
        `${watch_demo_api_link}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form to HubSpot");
      }

      console.log("Form submitted successfully to HubSpot");
      sessionStorage.setItem("formData", JSON.stringify(formData));
      setShowVideo(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <FormHeading h1="Your Information" />
      <div className="form-outer-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-field name">
              <label>
                Name <StyledSpan>*</StyledSpan>
              </label>
              <input
                type="text"
                value={formData.name}
                name="name"
                autoComplete="off"
                placeholder="Please enter your name"
                onChange={handleChange}
              />
              {errors.name && (
                <span className="error-message">{errors.name}</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-field email">
              <label>
                Email ID <StyledSpan>*</StyledSpan>
              </label>
              <input
                type="text"
                value={formData.email}
                name="email"
                autoComplete="off"
                placeholder="Please enter your email"
                onChange={handleChange}
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-field company-name">
              <label>
                Company Name <StyledSpan>*</StyledSpan>
              </label>
              <input
                type="text"
                value={formData.companyName}
                name="companyName"
                autoComplete="off"
                placeholder="Please enter your Company name"
                onChange={handleChange}
              />
              {errors.companyName && (
                <span className="error-message">{errors.companyName}</span>
              )}
            </div>
            <div className="form-field industry">
              <label>
                Industry <StyledSpan>*</StyledSpan>
              </label>
              <select
                value={formData.industry}
                name="industry"
                onChange={handleChange}
              >
                <option value="">Please select type of Industry</option>
                {IndustryList.map((industry) => (
                  <option key={industry.value} value={industry.value}>
                    {industry.label}
                  </option>
                ))}
              </select>
              {errors.industry && (
                <span className="error-message">{errors.industry}</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-field job-title">
              <label>
                Job Title <StyledSpan>*</StyledSpan>
              </label>
              <select
                value={formData.jobTitle}
                name="jobTitle"
                onChange={handleChange}
              >
                <option value="">Select your job Title</option>
                {jobTitles.map((job) => (
                  <option key={job.value} value={job.value}>
                    {job.label}
                  </option>
                ))}
              </select>
              {errors.jobTitle && (
                <span className="error-message">{errors.jobTitle}</span>
              )}
            </div>
            <div className="form-field team">
              <label>
                Department/Team <StyledSpan>*</StyledSpan>
              </label>
              <input
                type="text"
                value={formData.team}
                name="team"
                autoComplete="off"
                placeholder="Please enter the department name"
                onChange={handleChange}
              />
              {errors.team && (
                <span className="error-message">{errors.team}</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-field country">
              <label>
                Country <StyledSpan>*</StyledSpan>
              </label>
              <select
                value={formData.country}
                name="country"
                onChange={handleChange}
              >
                <option value="">Select your Country</option>
                {countries.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </select>
              {errors.country && (
                <span className="error-message">{errors.country}</span>
              )}
            </div>
          </div>
        </form>
      </div>

      <FormButtons>
        <a href="/">
          <CancelButton name="Cancel" />
        </a>
        <SubmitButton name="Next" onClick={handleSubmit} />
      </FormButtons>
    </>
  );
}
